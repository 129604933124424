import cloneDeep from 'lodash/cloneDeep'
import { defineStore } from 'pinia'

const defaultManualReview = {
    product: "",
    title: "",
    body: "",
    rating: 5,
    reviewer_name: "",
    reviewer_email: "",
    external_id: "",
    ip_address: "",
    external_created_at: "",
    location: "",
}

export const useReviewsStore = defineStore({
    id: 'reviews',
    state: () => {
        return {
            manualReview: cloneDeep(defaultManualReview)
        }
    },
    actions: {
        resetManualReview(){
            this.manualReview = cloneDeep(defaultManualReview)
        },
        async createManualReview(){
            try {
                const safeReview = {
                    review: {
                        ...this.manualReview,
                        product_id: this.manualReview.product.product_id
                    }
                }

                await this.$nuxt.$axios.post(`${process.env.API_V1_URL}/reviews/manual`, safeReview)

                this.resetManualReview()

                this.$nuxt.$toasts.add({
                    type: 'success',
                    title: 'Success!',
                    subtitle: 'Your review was successfully created.'
                })
            } catch (error) {
                this.$nuxt.$toasts.add({
                    type: 'error',
                    title: 'Failed to create review!',
                    subtitle: error.response.data.errors.join(".\n"),
                    bottomMessage: '',
                    error
                })
            }
        }
    }
})
