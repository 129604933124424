import { defineStore } from "pinia"
import numeral from 'numeral'
import { plansLibrary } from './pricing.static'
import { useSiteStore } from "./site"

export const usePricingStore = defineStore({
  id: 'pricing',
  state(){
    return {
      pricingPlans: [],
      planCopies: [{
        name: 'free',
        subtitle: 'Activity Monitor',
        price: '$0.00',
      }, {
        name: 'paid',
        subtitle: 'Room to Grow',
        pricePrefix: 'Starting At'
      }],
      pricingTableData: [{
        id: 'maxSessions',
        copy: 'Sessions / month',
        types: 'string',
        definition: 'In POP! a session is defined as every time the script is loaded in your shop. The script can be loaded multiple times during one customer visit by opening a new tab or entering the site twice. ',
        group: 'Usage',
        ranges: {
          free: '100',
          paid: 'Unlimited'
        },
      },{
        copy: 'Pop Types',
        types: 'string',
        group: 'Usage',
        ranges: {
          free: 'Activity Pops',
          paid: 'All Pops'
        }
      },{
        copy: 'Main Pop Settings',
        types: 'string',
        group: 'Features',
        ranges: {
          free: 'Activity Pops',
          paid: 'All Pops'
        }
      },{
        copy: 'Basic Pop Templates',
        types: 'boolean',
        group: 'Features',
        ranges: {
          free: true,
          paid: true
        }
      }, {
        copy: 'Advanced Pop Settings',
        types: 'boolean',
        group: 'Features',
        ranges: {
          free: false,
          paid: true
        }
      }, {
        copy: 'Advanced Pop Templates',
        types: 'boolean',
        group: 'Features',
        ranges: {
          free: false,
          paid: true
        }
      },{
        copy: 'Metrics & Tracking',
        types: 'boolean',
        group: 'Features',
        ranges: {
          free: false,
          paid: true
        }
      },{
        copy: 'Custom Pop Branding',
        types: 'boolean',
        group: 'Features',
        ranges: {
          free: false,
          paid: true
        }
      },{
        copy: 'Advanced Pop Types',
        types: 'boolean',
        group: 'Features',
        ranges: {
          free: false,
          paid: true
        }
      },{
        copy: 'Ticket Support',
        types: 'boolean',
        group: 'Help & Support',
        ranges: {
          free: true,
          paid: true
        }
      },{
        copy: 'Live Chat',
        types: 'boolean',
        group: 'Help & Support',
        ranges: {
          free: false,
          paid: true
        }
      }, {
          copy: 'Phone support',
          types: 'boolean',
          group: 'Help & Support',
          ranges: {
              free: false,
          }
      }],
      popupPerks:{
        free: [{
          copy: '100 Sessions',
          active: true,
        },{
          copy: 'Basic Pops',
          active: true,
        },{
          copy: 'Ticket Support',
          active: true,
        },{
          copy: 'Pop Templates',
          active: false,
        },{
          copy: 'Metrics & Tracking',
          active: false,
        },{
          copy: 'Live Chat Support',
          active: false,
        },{
          copy: 'Advanced Pops',
          active: false,
        },{
          copy: 'Pop Branding',
          active: false,
        }],
        paid: [{
          id: 'maxSessions',
          copy: 'Unlimited Sessions',
          active: true,
        },{
          copy: 'Pop Templates',
          active: true,
        },{
          copy: 'Ticket Support',
          active: true,
        },{
          copy: 'Pop Templates',
          active: true,
        }, {
          copy: 'All Pop Types',
          active: true,
        },{
          copy: 'Metrics & Tracking',
          active: true,
        },{
          copy: 'Live Chat Support',
          active: true,
        },{
          copy: 'Advanced Pops',
          active: true,
        },{
          copy: 'Pop Branding',
          active: true,
        }],
      },
    }
  },
  getters: {
    limitReached(){
      const siteStore = useSiteStore()
      return siteStore.currentSite?.utils?.free_plan_limit_reached
    },
    plans(){
      // Use new pricing plans library because no Shops and Sites that using old one exist.
      return plansLibrary?.['new']
    },
    plan(){
      return this.plans[0]
    },
    currentCharge(){
      const siteStore = useSiteStore()
      const { plan, price, price_type, pricing_plan, utils } = siteStore.currentSite

      if(utils.with_pricing_plan) {
        let price = pricing_plan.price

        if (siteStore.currentSite.discounted) {
          price = this.buildDiscountedPrice(price)
        }

        let format = '0,0.00'

        if (price !== '0.0' && price % 1 === 0) {
          format = '0,0'
        }

        return `$${numeral(price).format(format)}`
      }

      if(plan === 'free') return '$0.00'
      if(this.usage === null) return '--'

      const { currentPlan } = this

      const priceTypeSwitch = {
        discount: () => `$${Math.floor(currentPlan.priceInt * (1 - price / 100))}`,
        fixed: () => `$${price}`,
        default: () =>`$${currentPlan.priceInt}`
      }

      return priceTypeSwitch[price_type || 'default']()
    },
    currentPlan(){
      const siteStore = useSiteStore()
      const { source, plan, session_quota_usage } = siteStore.currentSite
      for(let plan of this.plans){
        if (plan.source !== 'all' && source !== plan.source) continue
        if (session_quota_usage < plan.startRange || session_quota_usage > plan.endRange ) continue
        return plan
      }
      return {}
    },
    maximumSessions(){
      const siteStore = useSiteStore()
      const { plan, free_plan_session_limit } = siteStore.currentSite

      if(siteStore.currentSite.utils.with_pricing_plan) {
          return siteStore.currentSite.pricing_plan.max_sessions
      }

      const { currentPlan } = this

      if(plan === 'free') return free_plan_session_limit || 100
      if(plan === 'paid') return currentPlan.endRange
    },
    selectablePricingPlans() {
      return this.pricingPlans.filter(plan => plan.selectable)
    },
  },
  actions: {
    buildDiscountedPrice(price) {
      const siteStore = useSiteStore()
      const { discount_percentage } = siteStore.currentSite

      return (price - (price * (discount_percentage / 100)))
    },
    async loadPricingPlans() {
      try {
        const queryString = 'filter[selectable]=true&sort[price]=asc&sort[unlimited]=asc&sort[max_sessions]=asc'
        const response = await this.$nuxt.$axios.$get(`${process.env.API_V1_URL}/pricing_plans?${queryString}`)

        this.pricingPlans = response.data
      } catch (error) {
        console.error(error)
      }
    },
    isPricingPlanAnnual(plan) {
        return plan?.type === "annual";
    },
    isPricingPlanMonthly(plan) {
        return plan?.type === "monthly";
    },
  }
})
