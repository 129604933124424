import pickBy from 'lodash/pickBy'
import { defineStore } from 'pinia'
import { useThemesStore } from '~/store/themes'
import { useReviewsStore } from '~/store/reviews'
import { useIntegrationsStore } from './integrations'
import { useTemplatesStore } from './templates'
import { useSiteStore } from './site'

export const usePopsStore = defineStore({
    id: 'pops',
    state(){
        return {
            examplePop: {
                id: 1,
                click_url: '',
                img: '',
                image_url: '',
                top: 'Jane in Miami, Florida United States',
                middle: 'Added to Cart Polaroid Camera',
                created_at: null
            },
            shopPops: [],
            recentDeleted: [],
            defaultExamplePop: {
                id: 1,
                click_url: '',
                img: '',
                image_url: '',
                top: 'Jane in Miami, Florida United States',
                middle: 'Added to Cart Polaroid Camera',
                created_at: null
            },
            forceShowIcon: false,
        }
    },
    getters: {
        microExamplePop(){
            const integrationsStore = useIntegrationsStore()
            const templatesStore = useTemplatesStore()
            const siteStore = useSiteStore()

            const { examplePop } = this
            if(!examplePop) return {}
            const { currentTemplate } = templatesStore
            const template = {
                ...currentTemplate,
                ...useThemesStore().currentThemeWithProofs
            }
            const { proof_locale, proof_pop_size, proof_show_powered_by } = siteStore.currentSite ?? {}
            const { defaultVariables } = integrationsStore
            const thePop = {
                closeEnabled: false,
                popClickEnabled: false,
                showIcon: template.handle?.includes('_visitors') || this.forceShowIcon,
                locale: proof_locale,
                size: proof_pop_size,
                pop: {
                    createdAt: examplePop.purchasedAt,
                    body: {
                        ...templatesStore.parsedBodyObject,
                    },
                    handle: template.handle,
                    imageUrl: [examplePop.img || examplePop.image_url],
                    highlightColor: template.proof_highlights_color || '#F89815',
                    siteIntegration: {
                        enabled: template.proof_enabled,
                        settings: {
                            ...(pickBy(template, (v, key) => key.startsWith('proof_')))
                        }
                    },
                    variables: defaultVariables
                },
                showPoweredBy: proof_show_powered_by,
            }

            if(template.handle === 'shopify_review'){
                const { manualReview } = useReviewsStore()
                thePop.pop.variables.first_name = manualReview.reviewer_name || defaultVariables.first_name
                thePop.pop.variables.city = manualReview.city || defaultVariables.city
                thePop.pop.variables.province = manualReview.province || defaultVariables.province
                thePop.pop.variables.country = manualReview.country || defaultVariables.country
                thePop.pop.variables.review_content = manualReview.body || defaultVariables.review_content
                thePop.pop.variables.product_title = manualReview.product?.title || defaultVariables.product_title
                thePop.pop.variables.rating = manualReview.rating || defaultVariables.rating
                thePop.pop.imageUrl = [manualReview.product?.image?.src || examplePop.img || examplePop.image_url] ?? []
            }


            // anonymize
            if(currentTemplate.proof_anonymize) {
                thePop.pop.variables.first_name = currentTemplate.proof_anonymize_text
            }

            if(template.handle !== ('shopify_abandoned_cart' || 'shopify_low_inventory')) {
                thePop.pop.body = $nuxt.$parseOpenEventBody(templatesStore.bodyObject, thePop.pop.variables, proof_locale)
            }

            if(template.handle === 'shopify_discount') {
                thePop.pop.body = $nuxt.$parseOpenEventBody(
                    {
                        top: 'Use {{code}} discount code to get 10% off your order',
                        middle: 'when you buy 2 or more {{customer_buys_title}}',
                    },
                    thePop.pop.variables,
                    proof_locale
                )
            }

            if(template.handle === 'nudge'){
                thePop.pop.body = thePop.pop.body.top
                thePop.pop.siteIntegration.settings.proof_media_url = (
                  thePop.pop.siteIntegration.settings.proof_media_url ||
                  "https://ucarecdn.com/90682028-176b-4b7c-b7bb-5dc83a3b3d1a/Welcome_Customer_1.png"
                )
                thePop.pop.siteIntegration.settings.proof_icon_url = (
                  thePop.pop.siteIntegration.settings.proof_icon_url ||
                  "https://cdn.jsdelivr.net/npm/@iconscout/unicons@4.0.0/svg/line/gift.svg"
                )
            }

            return thePop
        },
        staticMockedPop(){
            const siteStore = useSiteStore()
            const integrationsStore = useIntegrationsStore()
            const { proof_locale, proof_pop_size, proof_show_powered_by } = siteStore.currentSite ?? {}
            const { defaultVariables } = integrationsStore

            return {
                closeEnabled: false,
                popClickEnabled: false,
                locale: proof_locale,
                size: proof_pop_size,
                pop: {
                    createdAt: null,
                    body: {
                        top: "Jane in Miami, Florida United States",
                        middle: "Added to Cart: Cool Shirt"
                    },
                    handle: "shopify_storefront_add_to_cart",
                    imageUrl: ["https://ucarecdn.com/4ad1b8d5-a151-4afe-9239-3c444ba50867/image1.png"],
                    siteIntegration: {
                        enabled: true,
                        settings: {}
                    },
                    variables: defaultVariables
                },
                showPoweredBy: proof_show_powered_by,
            }
        }
    },
    actions: {
        async deletePopEvent(id) {
            try {
                const res = await this.$nuxt.$axios.$post(`site_events/delete`, {
                    id: id
                })
                this.$nuxt.$toasts.add({
                    type: 'success',
                    title: 'Pop Deleted!',
                    subtitle: 'Congrats! Your pop was deleted succesfully.'
                })
                this.recentDeleted.push(id)
            } catch (e) {
                this.$nuxt.$toasts.add({
                    type: 'error',
                    title: 'Pop delete',
                    subtitle: e,
                    e
                })
            }
        },
    }
})
