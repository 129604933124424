import { defineStore } from 'pinia'

export const useUpsellsGeneralSettingsStore = defineStore({
    id: 'upsells_general_settings',
    state(){
        return {
            sidebarIsOpen: false,
            loadingPreflight: true,
            planChangeModal: false,
            menuButtons: [{
                icon: 'star',
                slug: 'branding',
                label: 'Branding',
                subtitle: 'Optional',
            },{
                icon: 'globe',
                slug: 'language',
                label: 'Language',
                subtitle: 'Change pop language',
            },{
                icon: 'desktop-alt',
                slug: 'devices',
                label: 'Devices',
                subtitle: 'Choose Devices'
            },],
            devices: [{
                text: 'mobile',
                icon: 'mobile-android-alt',
                settingsKey: 'mobile_enabled'
            },{
                text: 'tablet',
                icon: 'tablet',
                settingsKey: 'tablet_enabled'
            },{
                text: 'desktop',
                icon: 'desktop',
                settingsKey: 'desktop_enabled'
            }],
            showPoweredBy: false,
            settings: [],
        }
    },
    actions: {
      async loadGeneralSettings() {
          try {
              this.loading = true
              const res = await this.$nuxt.$axios.$get(`${process.env.API_V1_URL}/upsells/settings`)
              this.settings = res.data.upsells_general_settings
              this.showPoweredBy = res.data.show_powered_by
          } catch (err) {
            this.$nuxt.$toasts.add({
                type: 'error',
                title: 'Failed to fetch upsells general settings!',
                subtitle: error.response.data.errors.join(".\n"),
                error
            })
          } finally {
            this.loading = false
          }
      },
      async saveGeneralSettings() {
          try {
              this.loading = true
              const params = {
                ...this.settings
              }
              const res = await this.$nuxt.$axios.$patch(`${process.env.API_V1_URL}/upsells/settings/current`, params)
              this.settings = res.data.upsells_general_settings
              this.showPoweredBy = res.data.show_powered_by
          } catch (err) {
            this.$nuxt.$toasts.add({
                type: 'error',
                title: 'Failed to save upsells general settings!',
                subtitle: error.response.data.errors.join(".\n"),
                error
            })
          } finally {
            this.loading = false
          }
      },
      setNewDevices(devices){
          const newDevices = {}
          this.devices.forEach(device => {
              newDevices[device.settingsKey] = devices.includes(device.text)
          })
          this.settings = {
              ...this.settings,
              ...newDevices
          }
      },
    }
})
