export const plansLibrary = {
    old: [{
      key: '1',
      range: '0 - 10k',
      price: '$29',
      priceInt: 29,
      copy: `The Growth Plan includes access to all features,
      premium live chat support, metrics, new feature launches and
      much more. Pricing is based on monthly sessions.`,
      value: '1',
      label: '100 - 10k',
      startRange: 0,
      endRange: 10000,
      source: 'all'
    },
    {
      key: '2',
      range: '10k - 50k',
      price: '$49',
      priceInt: 49,
      copy: `The Growth Plan includes access to all features,
      premium live chat support, metrics, new feature launches and
      much more. Pricing is based on monthly sessions.`,
      value: '2',
      label: '10k - 50k',
      startRange: 10000,
      endRange: 50000,
      source: 'all'
    },
    {
      key: '3',
      range: '50k+',
      price: '$129',
      priceInt: 129,
      copy: `The Growth Plan includes access to all features,
      premium live chat support, metrics, new feature launches and
      much more. Pricing is based on monthly sessions.`,
      value: '3',
      label: '50k+',
      startRange: 50000,
      endRange: 100000000000000000,
      source: 'wix'
    },
    {
      key: '3',
      range: '50k - 100k',
      price: '$129',
      priceInt: 129,
      copy: `The Growth Plan includes access to all features,
      premium live chat support, metrics, new feature launches and
      much more. Pricing is based on monthly sessions.`,
      value: '3',
      label: '50k - 100k',
      startRange: 50000,
      endRange: 100000,
      source: 'shopify'
    },
    {
      key: '4',
      range: '100k - 300k',
      price: '$299',
      priceInt: 299,
      copy: `The Growth Plan includes access to all features,
      premium live chat support, metrics, new feature launches and
      much more. Pricing is based on monthly sessions.`,
      value: '4',
      label: '100k - 300k',
      startRange: 100000,
      endRange: 300000,
      source: 'shopify'
    },
    {
      key: '5',
      range: '300k+',
      price: 'Contact',
      priceInt: 499,
      copy: `The Growth Plan includes access to all features,
      premium live chat support, metrics, new feature launches and
      much more. Pricing is based on monthly sessions.`,
      value: '5',
      label: '300k+',
      startRange: 300000,
      endRange: 100000000000000000,
      source: 'shopify'
    }],
    new: [{
      key: '1',
      range: '0 - 2k',
      price: '$29',
      priceInt: 29,
      value: '1',
      label: 'Up to 2k',
      startRange: 0,
      endRange: 2000,
      source: 'all'
    },
    {
      key: '2',
      range: '2k - 10k',
      price: '$79',
      priceInt: 79,
      value: '2',
      label: '2k - 10k',
      startRange: 2000,
      endRange: 10000,
      source: 'all'
    },
    {
      key: '3',
      range: '10k - 50k',
      price: '$129',
      priceInt: 129,
      value: '3',
      label: '10k - 50k',
      startRange: 10000,
      endRange: 50000,
      source: 'all'
    },
    {
      key: '4',
      range: '50k - 100k',
      price: '$199',
      priceInt: 199,
      value: '4',
      label: '50k - 100k',
      startRange: 50000,
      endRange: 100000,
      source: 'all'
    },
    {
      key: '5',
      range: '100k+',
      price: '$299',
      priceInt: 299,
      value: '5',
      label: '100k+',
      startRange: 100000,
      endRange: 1000000000000000,
      source: 'shopify'
    }]
  }
